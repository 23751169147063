<template>
  <div>
    <table-filters
      v-if="date_column || options.customFilters && options.customFilters.length > 0"
      :custom_filters="options.customFilters"
      :date_column="date_column"
      @data_filtered="dataFiltered"
    />

    <page-functions
      v-if="!hiddenFilters"
      :actual-page="values.current_page"
      :last-page="values.last_page"
      :search-input="search"
      @changing_page="pageChanged"
      @searching="searched"
      @changing_showing="showChanged"
    />

    <md-table
      ref="vtable"
      :class="opts.tableClass"
    >
      <!-- HEAD -->
      <md-table-row :class="opts.theadClass">
        <md-table-head v-if="checkeable">
          <md-checkbox
            v-model="allChecked"
            type="checkbox"
            class="mt-2"
            @change="$emit('checked', { value: $event, all: true })"
          />
        </md-table-head>
        <md-table-head
          v-for="head in headers"
          :key="head.key"
          :style="{width:head.width ? head.width+'%' : 'auto'}"
        >
          <span
            :class="head.sortable ? 'sortable' : ''"
            @click="sorted(head)"
          >
            <strong v-if="head.strong === true">
              {{ head.mask ? head.mask.ucwords() : head.title ? head.title.ucwords() : '' }}
            </strong>
            <span v-else-if="head.no_format">
              {{ head.mask || head.title }}
            </span>
            <span v-else>
              {{ head.mask ? head.mask.ucwords() : head.title ? head.title.ucwords() : '' }}
            </span>

            <!-- Sort Icons -->
            <i
              v-if="head.sortable
                && (sortedBy === head.title
                  || sortedBy === head.sort_value)"
              class="fa"
              :class="sortedDir === 'desc' ? 'fa-sort-down' : 'fa-sort-up'"
            />
            <i
              v-if="head.sortable
                && sortedBy !== head.title
                && sortedBy !== head.sort_value"
              class="fa fa-sort"
            />
          </span>
        </md-table-head>
        <md-table-head />
      </md-table-row>

      <!-- LOADER -->
      <md-table-row v-if="loading">
        <md-table-cell
          :colspan="headers.length + 1"
          style="text-align: center"
        >
          <loader />
        </md-table-cell>
      </md-table-row>

      <md-table-row
        v-else-if="!values
          || values.length === 0
          || Object.keys(values).length === 0
          && values.constructor === Object"
      >
        <md-table-cell
          :colspan="headers.length + 1"
          style="text-align: center"
        >
          <p>There are no items to show</p>
        </md-table-cell>
      </md-table-row>

      <!-- BODY -->
      <md-table-row
        v-for="(item,a) in showValues.data"
        :key="item.id"
      >
        <md-table-cell v-if="checkeable">
          <md-checkbox
            v-if="item.checkeable"
            v-model="item.checked"
            class="md-primary"
            @change="$emit('checked', { value: $event, index: a })"
          />
        </md-table-cell>

        <template>
          <md-table-cell
            v-for="(head,b) in headers"
            :key="b"
          >
            <div v-if="head.color">
              <div
                class="color-pill"
                :style="{'background-color': item[head.title]}"
              />
            </div>
            <div v-else-if="head.checkeable">
              <md-checkbox
                v-model="item[head.title]"
                class="md-primary m-0"
                :disabled="!editable || !item.editable || validateExtraParam(item)"
                @change="$emit('itemChecked', item)"
              />
            </div>
            <div v-else-if="head.editable">
              <template v-if="!head.editableType || head.editableType === 'text'">
                <form-text
                  v-model="item[head.title]"
                  :disabled="!editable || !item.editable"
                  :label="head.mask ? head.mask.ucwords() : head.title ? head.title.ucwords() : ''"
                />
              </template>
              <template v-else-if="head.editableType === 'number'">
                <form-number
                  v-model="item[head.title]"
                  :disabled="!editable || !item.editable"
                  :label="head.mask ? head.mask.ucwords() : head.title ? head.title.ucwords() : ''"
                />
              </template>
            </div>

            <div v-else>
              <div
                v-if="head.htmlFormat === true"
                v-html="parseValue(item, head)"
              />
              <div
                v-else
                :title="parseValue(item, head, true)"
              >
                <b v-if="head.strong">
                  {{ parseValue(item, head) }}
                </b>
                <span v-else>
                  {{ parseValue(item, head) }}
                </span>
              </div>
            </div>
          </md-table-cell>
        </template>

        <md-table-cell style="text-align: right">
          <div
            v-if="actions.length"
            class="btn-group"
          >
            <md-button
              v-for="(act,i) in actions.filter(a => item[a.callback] !== false)"
              :key="i"
              class="md-simple md-icon-button"
              :class="act.buttonClass"
              :disabled="act.disabledParam !== undefined && !item[act.disabledParam]"
              @click="$emit(act.callback,item, false)"
              @click.middle="$emit(act.callback,item, true)"
            >
              <md-icon>{{ act.icon }}</md-icon>
              <md-tooltip>{{ act.tooltip }}</md-tooltip>
            </md-button>
          </div>
        </md-table-cell>
      </md-table-row>

      <!-- FOOT -->
      <md-table-row v-if="!hiddenFooter">
        <md-table-cell
          v-if="values.data && values.data.length > 0"
          colspan="1000"
          style="text-align: center"
        >
          Showing from <b>{{ values.from }}</b> to
          <b>{{ values.to }}</b> of <b>{{ values.total }}</b> items.
        </md-table-cell>
        <md-table-cell
          v-if="values.data && values.data.length <= 0"
          colspan="1000"
          style="text-align: center"
        >
          No items to show.
        </md-table-cell>
      </md-table-row>
    </md-table>

    <page-functions
      v-if="!hiddenFilters"
      :pager-only="true"
      :actual-page="values.current_page"
      :last-page="values.last_page"
      @changing_page="pageChanged"
      @changing_showing="showChanged"
    />
  </div>
</template>

<script>
/**
   * Received values
   *
   * # HEADERS
   * - title : string / nullable
   * - mask : string / nullable
   * - width: number(%) / nullable
   * - sortable: bool
   *
   * # VALUES
   * - pagination from laravel (https://laravel.com/docs/5.8/pagination)
   *
   * # ACTIONS
   * - buttonClass
   * - callback
   * - tooltip
   * - icon
   *
   * # OPTIONS
   * - tableClass
   * - theadClass
   * - tbodyClass
   *
   * Emitted values
   * - changed (it will modify the search parameters)
   */
import PageFunctions from '@/components/Tables/PageFunctions';
import TableFilters from '@/components/Tables/tableFilters';
import loader from '@/components/loader';
import moment from 'moment';
import '../../utils/ucwords';
import {
  FormText,
  FormNumber,
} from '@/components/Inputs';

export default {
  name: 'VueTables',
  components: {
    PageFunctions,
    FormNumber,
    FormText,
    TableFilters,
    loader,
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    actions: {
      type: Array,
      default() {
        return {};
      },
    },
    truncate: {
      type: [Number, Boolean],
      default: 50,
    },
    editable: {
      type: [Boolean],
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    hiddenFilters: {
      type: Boolean,
      default: false,
    },
    hiddenFooter: {
      type: Boolean,
      default: false,
    },
    checkeable: {
      type: Boolean,
      default: false,
    },
    extraParamValidation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      opts: {
        tableClass: 'table table-bordered',
        theadClass: '',
        tbodyClass: '',
      },
      sortedBy: '',
      sortedDir: 'asc',
      date_column: null,
      vTableParams: {
        page: 1,
        search: null,
        sortBy: null,
        sortDir: null,
        filters: null,
        per_page: 25,
      },
      allChecked: false,
      loading: false,
    };
  },
  computed: {
    showValues() {
      if (this.loading) return [];

      return this.values;
    },
  },
  watch: {
    values() {
      this.loading = false;
    },
  },
  mounted() {
    let dateCounter = 0;
    this.headers.map((x) => {
      if (x.date) {
        this.date_column = x.title;
        dateCounter += 1;
      }

      // Check if column is boolean type,
      // it must have values for each case
      if (x.boolean) {
        if (!x.booleanValues) { throw new Error('The column type boolean has no booleanValues'); }

        if (!x.booleanValues.true) throw new Error('The column type boolean has no booleanValues.true');

        if (!x.booleanValues.false) throw new Error('The column type boolean has no booleanValues.false');
      }

      if (dateCounter > 1) throw new Error('No puede haber mas de un campo DATE=TRUE');
      return x;
    });

    if (this.options && this.options.customFilters && !Array.isArray(this.options.customFilters)) throw new Error('Los CustomFilters no son un array');
    if (this.options && this.options.tableClass) this.opts.tableClass = this.options.tableClass;
    if (this.options && this.options.theadClass) this.opts.theadClass = this.options.theadClass;
    if (this.options && this.options.tbodyClass) this.opts.tbodyClass = this.options.tbodyClass;
  },
  methods: {
    pageChanged(val) {
      this.vTableParams.page = val;
      this.changed();
    },
    searched(val) {
      if (val && val !== '' && val.length < 3) {
        this.fireError('Search values must be at least 3 characters');
        return;
      }

      this.vTableParams.page = 1;
      this.vTableParams.search = val !== '' ? val : null;
      this.changed();
    },
    showChanged(val) {
      this.vTableParams.page = 1;
      this.vTableParams.per_page = val;
      this.changed();
    },
    dataFiltered(val) {
      this.vTableParams.page = 1;
      this.vTableParams.filters = val;
      this.changed();
    },
    sorted(item) {
      if (item.sortable) {
        if (this.sortedBy === item.title || this.sortedBy === item.sort_value) {
          this.sortedDir = this.sortedDir === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortedDir = 'asc';
        }

        this.sortedBy = item.sort_value ? item.sort_value : item.title;

        this.vTableParams.sortBy = this.sortedBy;
        this.vTableParams.sortDir = this.sortedDir;

        this.changed();
      }
    },
    changed() {
      const mainPanel = document.querySelector('.main-panel');
      mainPanel.scrollIntoView();

      this.loading = true;
      this.$emit('changed', this.vTableParams);
    },
    nestedTitle(item, val) {
      const array = val.split('.');
      let aux = item;

      array.forEach((value) => {
        aux = aux[value] === null || aux[value] === undefined ? '-' : aux[value];
      });

      return aux;
    },
    moment(date) {
      if (!date || date === '-') return '-';
      return moment(date).format(this.$store.getters['school_settings/settings'].date_format);
    },
    onUpdate(val, item) {
      this.$emit('onUpdate', { val, item });
    },
    validateExtraParam(item) {
      if (this.extraParamValidation !== null) {
        return item[this.extraParamValidation];
      }

      return !this.editable;
    },
    parseValue(item, head, full = false) {
      let result = this.nestedTitle(item, head.title);

      if (head.dateFormat === true && result !== '-') result = this.dateFormat(result);
      else if (head.dateTimeFormat === true && result !== '-') result = this.dateTimeFormat(result);
      else if (head.boolean === true) result = head.booleanValues[result === true];
      else if (head.callback !== undefined) result = head.callback(result);

      if (head.pre) {
        if (['€', '$'].includes(head.pre)) {
          if (typeof result === 'number') {
            result = `${head.pre}${result.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
          } else {
            result = `${head.pre}${parseFloat(result).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
          }
        } else result = `${head.pre}${result}`;
      }
      if (head.after) result = `${result}${head.after}`;

      if (full) return result || '-';

      if (head.htmlFormat) {
        return result;
      }

      if (head.max_chars && result.length > head.max_chars) result = `${result.slice(0, head.max_chars)}...`;

      if (this.truncate && result?.length > this.truncate) result = `${result.slice(0, this.truncate)}...`;

      return result || '-';
    },
    init() {
      this.loading = true;
      this.$emit('changed', {
        page: 1,
        search: null,
        sortBy: null,
        sortDir: null,
        filters: null,
        per_page: 25,
      });
    },
  },
};
</script>

<style scoped>
  table td, table tr {
    font-size: 98%;
  }

  tr:nth-child(even) {
    background-color: #eeeeee;
  }

  .sortable {
    cursor: pointer;
  }

  td, th {
    padding: 5px !important;
  }

  .btn-group {
    margin: 5px 1px !important;
  }

  td {
    border-top: none;
    white-space: nowrap;
  }

  .btn.btn-sm {
    padding: 4px;
    width: 25px;
  }

  .btn-sm i {
    font-size: 100% !important;
  }

  .table-responsive {
    overflow-y: hidden;
  }

  .color-pill {
    height: 30px;
    width: 45px;
    outline: 1px solid rgba(0,0,0, 0.1);
  }
</style>
