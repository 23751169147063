<template>
  <div class="row">
    <div class="col">
      <div v-if="showFilters">
        <div
          v-if="customFilters && customFilters.length > 2"
          class="row"
        >
          <div
            v-for="item in customFilters"
            :key="item.title"
            class="col-sm form-group"
          >
            <label class="bmd-label">{{ item.title.ucwords() }}:</label>
            <select
              v-model="filters.custom[item.column]"
              class="form-control"
              :name="item.title"
            >
              <option
                v-for="opt in item.options"
                :key="opt.value"
                :value="opt.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <search-person
            :class="'col-sm form-group'"
            :tipo-persona="'cliente'"
            :value="null"
            :not-create="true"
            @input="onSearchPerson"
          />
          <div
            v-for="item in customFilters"
            v-if="customFilters && customFilters.length < 2"
            :key="item.title"
            class="col-sm form-group"
          >
            <label class="bmd-label">{{ item.title.ucwords() }}:</label>
            <select
              v-model="filters.custom[item.column]"
              class="form-control"
              :name="item.title"
            >
              <option
                v-for="opt in item.options"
                :key="opt.value"
                :value="opt.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <button
              class="btn btn-default btn-sm btn-block"
              @click="filterData"
            >
              Filtrar
            </button>
          </div>
          <div class="col-sm">
            <button
              class="btn btn-warning btn-sm btn-block"
              @click="removeFilters"
            >
              Remover Filtros
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            <button
              class="btn btn-info btn-sm btn-block"
              @click="showFilters = false"
            >
              <i class="fa fa-chevron-up" />
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="row"
      >
        <div class="col-sm">
          <button
            class="btn btn-info btn-sm btn-block"
            @click="showFilters = true"
          >
            Filtros
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dateColumn: {
      type: Boolean,
    },
    customFilters: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showFilters: false,
      filters: {
        date_col: null,
        date_from: null,
        date_to: null,
        custom: {},
      },
    };
  },
  mounted() {
  },
  methods: {
    filterData() {
      if (this.date_column) this.filters.date_col = this.date_column;

      let send = false;
      if (this.filters.date_from) send = true;
      if (this.filters.date_to) send = true;

      if (Object.keys(this.filters.custom).length > 0) send = true;

      if (send) this.$emit('data_filtered', this.filters);
    },

    removeFilters() {
      this.filters = {
        date_col: null,
        date_from: null,
        date_to: null,
        custom: {},
      };
      this.$emit('data_filtered', this.filters);
    },

    onSearchPerson(evt) {
      this.filters.custom.persona_id = evt.value;
    },
  },
};
</script>

<style scoped>
    select.form-control {
        height: 36px !important;
    }
</style>
