<template>
  <div class="md-layout md-gutter pageFunctions">
    <div
      v-if="!pagerOnly"
      class="md-layout-item text-center"
    >
      <div
        class="form-group withSelect"
        style="display: inline-flex;"
      >
        <label>Show</label>
        <select
          v-model="showing"
          class="form-control"
          style="min-width: 50px; text-align-last:center;"
          @change="changeShowing"
        >
          <option value="25">
            25
          </option>
          <option value="50">
            50
          </option>
          <option value="100">
            100
          </option>
          <option value="500">
            500
          </option>
        </select>
        <label>Items</label>
      </div>
    </div>

    <div class="md-layout-item mt-2 text-center">
      <ul class="mb-0 pagination justify-content-center">
        <li
          class="page-item hideMobile"
          :class="currentPage - 1 === 0 ? 'disabled' : ''"
          @click.prevent="changePage(1)"
        >
          <a
            class="page-link"
            href="#"
          >First</a>
        </li>
        <li
          v-for="page in pagesList"
          :key="page.number"
          class="page-item hideMobile"
          :class="currentPage === page.number ? 'active' : ''"
          @click.prevent="changePage(page.number)"
        >
          <a
            class="page-link"
            href="#"
          >{{ page.number }}</a>
        </li>
        <li
          class="page-item hideMobile"
          :class="!lastPage || currentPage + 1 > lastPage ? 'disabled' : ''"
          @click.prevent="changePage(lastPage)"
        >
          <a
            class="page-link"
            href="#"
          >Last</a>
        </li>

        <li
          class="page-item onlyMobile"
          :class="currentPage - 1 === 0 ? 'disabled' : ''"
          @click.prevent="changePage(currentPage - 1)"
        >
          <a
            class="page-link"
            href="#"
          >Previous</a>
        </li>
        <li
          class="page-item onlyMobile"
          :class="currentPage + 1 > lastPage ? 'disabled' : ''"
          @click.prevent="changePage(currentPage + 1)"
        >
          <a
            class="page-link"
            href="#"
          >Next</a>
        </li>
        <li class="page-item hideMobile total-pages">
          <a
            class="page-link"
          >Pages: {{ lastPage }}</a>
        </li>
      </ul>
    </div>

    <div
      v-if="!pagerOnly"
      class="md-layout-item text-center"
    >
      <div
        v-if="searchInput"
        class="form-group"
      >
        <input
          v-model="searchValue"
          type="text"
          placeholder="Search..."
          class="form-control"
          @keydown.enter="search"
        >

        <md-button
          class="md-primary md-just-icon md-round md-sm"
          @click="search"
        >
          <md-icon>search</md-icon>
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
/**
   * # INPUT
   * - lastPage | required
   *
   * # OUTPUT
   * - changing_page
   * - searching
   * - changing_showing
   */
export default {
  props: {
    lastPage: {
      type: Number,
      default: 0,
    },
    actualPage: {
      type: Number,
      default: 0,
    },
    searchInput: {
      type: Boolean,
      default: true,
    },
    pagerOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showing: 25,
      currentPage: 1,
      pagesList: [],
      searchValue: null,
    };
  },
  watch: {
    lastPage() {
      this.doPagesList();
    },
    actualPage(val) {
      this.currentPage = val;
      this.doPagesList();
    },
  },
  mounted() {
    this.doPagesList();
  },
  methods: {
    doPagesList() {
      // Reseteo la lista
      this.pagesList = [];

      // Agrego los items antes del actual
      if (this.currentPage > 3) {
        for (let i = this.currentPage; i >= this.currentPage - 2; i -= 1) {
          this.pagesList.unshift({
            number: i,
          });
        }
      } else {
        for (let i = 1; i <= this.currentPage; i += 1) {
          this.pagesList.push({
            number: i,
          });
        }
      }

      // Agrego los items despues del actual
      let rest = this.lastPage - this.currentPage;

      if (rest !== 0) {
        // reasigno a la cantidad maxima de paginas por delante
        rest = rest > 2 ? 2 : rest;

        for (let i = this.currentPage + 1; i <= this.currentPage + rest; i += 1) {
          this.pagesList.push({
            number: i,
          });
        }
      }
    },

    changePage(number) {
      if (number > 0 || number <= this.lastPage()) {
        this.currentPage = number;
        this.doPagesList();
        this.$emit('changing_page', number);
      }
    },

    changeShowing() {
      this.$emit('changing_showing', this.showing);
    },

    search() {
      this.$emit('searching', this.searchValue);
    },
  },
};
</script>

<style scoped lang="scss">
  .dark-edition {
    .page-link {
      color: #ffffff !important;
    }
  }

  .page-link {
    min-width: 2.6em;
    margin-right: .5em;
    text-align: center;
    border-radius: 4em;
    color: black;
  }

  .page-item:first-child .page-link {
    margin-left: 0;
  }

  .page-item:first-child .page-link, .page-item:last-child .page-link {
    margin-left: 0;
    border-radius: 4rem;
  }

  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .onlyMobile {
    display: none;

    @media (max-width: 986px) {
      display: block;
    }
  }

  .notMobile {
    display: block;

    @media (min-width: 986px) {
      display: none;
    }
  }

  .pagination {
    display: inline-flex;
    margin: auto;
  }
  .form-group.withSelect label{
    padding-top: 7px;
  }
  .pageFunctions {
    padding-bottom: 1em;
  }
  .total-pages {
    margin-left: 1em;
  }
  .total-pages a {
     cursor: default !important;
  }
</style>
